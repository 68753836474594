import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import '../../styles/style.css';
import { getFirestore, collection, getDocs, query, where, doc ,getDoc } from 'firebase/firestore';
import logo from  '../pages/admin/egehan.jpeg';
import AuthorProfile from './AuthorProfile';

const LastCommentsWidget = () => {
    const [posts, setPosts] = useState([]);
    const [userData, setUserData] = useState([]);
    
    useEffect(() => {
      const fetchPosts = async () => {
        const db = getFirestore();
        const postsCollection = collection(db, 'comments');
        const querySnapshot = await getDocs(postsCollection);
        const postData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setPosts(postData);
      };
  

    fetchPosts();
   
  },);
  // ...
  return (
    <>
                
     
                  {posts.map((post, index) => (
                    
                    <ol className="list-unstyled mb-0">
                    <li>
                      <a href="#"> 
                      <div  >
                    <h6 className="mb-0"> <a  href={`/blog/${post.slug}`} > <p  >{post.text}</p> </a></h6>
                    <small className="text-body-secondary">{post.timestamp}</small>
                  </div>
                  </a>
                  </li>       
                  </ol>
                  ))}
                  
         
    </>
  );
}

export default LastCommentsWidget;
