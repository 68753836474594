import React, { useState } from 'react';
import { auth, db } from '../../../firebase'; // Correct import path
import { addDoc, collection } from 'firebase/firestore'; // Import Firestore functions directly
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { updateProfile } from 'firebase/auth';

const SignUpPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
  
    try {
      const { name, email, password } = formData;
  
      // Create user in Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
  
      // Add user data, including the password (not recommended), to Firestore 'users' collection
      await addDoc(collection(db, 'users'), {
        uid: user.uid,
        name: user.displayName,
        email: user.email,
        password: password, // Storing password in Firestore (not recommended)
      });
  
      // Redirect or perform any additional actions after a successful sign-up
      // e.g., history.push('/dashboard');
    } catch (error) {
      console.error('Error signing up:', error.message);
      // Handle errors (e.g., display an error message to the user)
    }
  };
  

  return (
    <div className="container h-100">
      <div className="row h-100">
        <div className="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
          <div className="d-table-cell align-middle">

            <div className="text-center mt-4">
              <h1 className="h2">Bize Katıl</h1>
              <p className="lead">
                Blogumuzda sende yer al!
              </p>
            </div>

            <div className="card">
              <div className="card-body">
                <div className="m-sm-4">
                <form onSubmit={handleSignUp}>
      <div className="form-group">
        <label>Adınız</label>
        <input
          className="form-control form-control-lg"
          type="text"
          name="name"
          placeholder="Enter your name"
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <label>Email</label>
        <input
          className="form-control form-control-lg"
          type="email"
          name="email"
          placeholder="Enter your email"
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <label>Şifre</label>
        <input
          className="form-control form-control-lg"
          type="password"
          name="password"
          placeholder="Enter password"
          onChange={handleChange}
        />
      </div>
      <div className="text-center mt-3">
        <button type="submit" className="btn btn-lg btn-primary">
          Kaydı tamamla
        </button>
      </div>
    </form>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUpPage;
