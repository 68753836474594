import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import '../../styles/style.css';
import { getFirestore, collection, getDocs, query, where, doc, getDoc } from 'firebase/firestore';
import logo from  '../pages/admin/egehan.jpeg';
const AuthorProfile = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);

 // ...

useEffect(() => {
    const fetchPost = async () => {
      const db = getFirestore();
      const postDocRef = doc(db, 'users', 'cIMHBh9Jmca0hzGyXc2VdnF5jQl2');
  
      try {
        const postDocSnapshot = await getDoc(postDocRef);
  
        if (postDocSnapshot.exists()) {
          const postData = postDocSnapshot.data();
          setPost(postData);
        } else {
          console.error('Post not found');
        }
      } catch (error) {
        console.error('Error fetching post:', error);
      }
    };
  
    fetchPost();
  }, [slug]);
  
  // ...
  

  if (!post) {
    return <div>Loading...</div>;
  }

  return (
    <> 
     <div className="p-4 mb-3 bg-body-tertiary rounded">
      
            <h4 className="fst-italic">Hakkımda</h4>
            <p className="mb-0">
            Merhaba! Ben {post.displayName}
            <p>{post.bio}</p>
            </p>
          </div>             
  
    </>
  );
}

export default AuthorProfile;
