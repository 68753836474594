import React, { useEffect } from 'react';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';

const LoginPage = ({ setIsAuthenticated }) => {
  let app; // Declare 'app' variable

  useEffect(() => {
    // Initialize Firebase app
    const firebaseConfig = {
      apiKey: "AIzaSyAO-AVsbAqUOSuWWBQ4OEt57BhSpLU4KhQ",
      authDomain: "vastseablogweb.firebaseapp.com",
      projectId: "vastseablogweb",
      storageBucket: "vastseablogweb.appspot.com",
      messagingSenderId: "1025679968798",
      appId: "1:1025679968798:web:5b157c452e26d4e69abc0f",
      measurementId: "G-6ZVDRQHV6M"
    };

    app = initializeApp(firebaseConfig);
  }, []); // Run only once on component mount
  const handleGoogleLogin = async () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
  
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
  
      // Check if the user's email is the specified one
      if (user.email === 'egehangamer4606@gmail.com') {
        // Save user data to Firestore
        const db = getFirestore(app);
        const usersCollection = doc(db, 'users', user.uid);
  
        // Check if the user already exists in Firestore
        const docSnap = await getDoc(usersCollection);
  
        if (!docSnap.exists()) {
          // If not, add the user to Firestore
          await setDoc(usersCollection, {
            email: user.email,
            displayName: user.displayName,
            // Add other user data as needed
          });
        }
  
        // Update isAuthenticated state
        setIsAuthenticated(true);
      } else {
        // Handle the case where the user's email is not the specified one.
        console.log('Invalid email address');
      }
    } catch (error) {
      console.error('Error during Google login:', error.message);
    };
  };
  return (
    <div id="main-wrapper" className="container">
      <div className="row justify-content-center">
        <div className="col-xl-10">
          <div className="card border-0">
            <div className="card-body p-0">
              <div className="row no-gutters">
                <div className="col-lg-6">
                  <div className="p-5">
                    <div className="mb-5">
                      <h3 className="h4 font-weight-bold text-theme">Login</h3>
                    </div>

                    <h6 className="h5 mb-0">Welcome back!</h6>
                    <p className="text-muted mt-2 mb-5">Enter your email address and password to access the admin panel.</p>

                  
      <button type="button" onClick={handleGoogleLogin} className="btn btn-danger">
        Sign In with Google
      </button>
                  </div>
                </div>

                <div className="col-lg-6 d-none d-lg-inline-block">
                  <div className="account-block rounded-right">
                    <div className="overlay rounded-right"></div>
                    <div className="account-testimonial">
                      <h4 className="text-white mb-4">This beautiful theme is yours!</h4>
                      <p className="lead text-white">"Best investment I made for a long time. Can only recommend it for other users."</p>
                      <p>- Admin User</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* end card-body */}
          </div>
          {/* end card */}

          <p className="text-muted text-center mt-3 mb-0">
            Don't have an account? <a href="/register" className="text-primary ml-1">Register</a>
          </p>

          {/* end row */}
        </div>
        {/* end col */}
      </div>
      {/* Row */}
    </div>
  );
}

export default LoginPage;
