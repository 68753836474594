import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import '../../styles/style.css';
import { getFirestore, collection, getDocs, query, where, doc, updateDoc } from 'firebase/firestore';
import logo from '../pages/admin/egehan.jpeg';
import AuthorProfile from './AuthorProfile';
import Categorys from './Categorys';
import CommentBlock from './Comment';
import InstagramWidget from './InstagramWidget';
import LastCommentsWidget from './LastCommentsWidget';
import RecentPosts from './RecentPost';
const BlogSingle = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      const db = getFirestore();
      const postsCollection = collection(db, 'posts');
      const q = query(postsCollection, where('url', '==', slug));

      try {
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const postData = querySnapshot.docs[0].data();
          // Görüntüleme sayısını artır ve Firestore'da güncelle
          const updatedViews = (postData.views || 0) + 1;
          await updateDoc(doc(db, 'posts', querySnapshot.docs[0].id), { views: updatedViews });
          setPost({ ...postData, views: updatedViews });
        } else {
          console.error('Post not found');
        }
      } catch (error) {
        console.error('Error fetching post:', error);
      }
    };

    fetchPost();
  }, [slug]);

  const handleCommentAdded = (comment) => {
    setPost({
      ...post,
      comments: [...(post.comments || []), comment]
    });
  };

  const copyToClipboard = () => {
    const input = document.getElementById('sourceInput');
    input.select();
    document.execCommand('copy');
  };

  return (
    <>
      
        <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet"></link>
     
      <div className="row g-5">
      <div className="col-md-8">
      

        {/* First Blog Post */}
        {post ? (
<>  <h3 className="pb-4 mb-4 fst-italic border-bottom">
          {post.title}
        </h3> 
            <article className="blog-post">
              <div className="article-img">
                <img style={{ width: '100%', maxHeight: '600px' }} src={post.image} title={post.title} alt={post.title} />
              </div>
              <h2 className="display-5 link-body-emphasis mb-1">{post.title}</h2>
              <p className="blog-post-meta">{post.timestamp.toDate().toLocaleDateString()} <a href="#"> Egehan KAHRAMAN </a> 👁️: {post.views} </p>
              <div dangerouslySetInnerHTML={{ __html: post.content }}></div>
              <nav className="blog-pagination" aria-label="Pagination">

              <div className="input-group mb-3">
        <input
          id="sourceInput"
          type="text"
          className="form-control"
          value={ `vastseablog.com/blog/${post.url}`}
          readOnly
          aria-label="Kaynak Kutusu"
          aria-describedby="copy-button"
        />
        <button
          className="btn btn-outline-secondary"
          type="button"
          id="copy-button"
          onClick={copyToClipboard}
        >
          Kopyala
        </button>
      </div>
              {post.tags && post.tags.map((tag, index) => (
                  <>
                   <a className="btn btn-outline-primary rounded-pill" href={`/etiket/${tag}`} >
                   <a key={index}  >{tag}</a>
                   </a>
                   
               
                   </>
              ))}
                <div className="social-media-share">
        <h5 className="mb-3">Paylaş:</h5>
        <ul className="list-unstyled list-inline mb-0">
          <li className="list-inline-item">
            
            <a href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(`vastseablog.com/blog/${post.url}`)}`} target="_blank" rel="noopener noreferrer">
              <i className="fa fa-twitter"></i>
            </a>
          </li>
          <li className="list-inline-item">
            <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(`vastseablog.com/blog/${post.url}`)}`} target="_blank" rel="noopener noreferrer">
              <i className="fa fa-facebook"></i>
            </a>
          </li>
          <li className="list-inline-item">
            <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(`vastseablog.com/blog/${post.url}`)}`} target="_blank" rel="noopener noreferrer">
              <i className="fa fa-linkedin"></i>
            </a>
          </li>
        
        </ul>
      </div>
              </nav>
            </article>
            <CommentBlock />
            </>
          ) : (
            <p>Loading...</p>
          )}

       
        <nav className="blog-pagination" aria-label="Pagination">
          
        </nav>
      </div>

      {/* Sidebar */}
      <div className="col-md-4">
        <div className="position-sticky" style={{ top: '2rem' }}>
         <AuthorProfile />

          {/* Recent Posts */}
          <div>
      
          </div>
          <div >
          <h4 className="fst-italic"> Son yorumlar</h4>
            <LastCommentsWidget />
          </div>

          {/* Archives  
          <div className="p-4">
            <h4 className="fst-italic">Archives</h4>
            <ol className="list-unstyled mb-0">
              <li><a href="#">March 2021</a></li>
              <li><a href="#">February 2021</a></li>
                
            </ol>

          </div>
*/}
          {/* Elsewhere */}
          <div className="p-4">
            <h4 className="fst-italic">Sosyal Medyada Biz!</h4>
            <ol className="list-unstyled">
              <li><a href="https://github.com/VastSea0/">GitHub</a></li>
              <li><a href="https://twitter.com/VastSeaBlog">Twitter</a></li>
              <li><a href="https://instagram.com/vastseaoffical">Instagram</a></li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default BlogSingle;
