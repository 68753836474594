import React, { useEffect, useState } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { Link, useNavigate } from 'react-router-dom'; // Import necessary React Router components
import '../../styles/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function Header() {
  const [posts, setPosts] = useState([]);
  const [searchInput, setSearchInput] = useState('');
   
  useEffect(() => {
    const fetchPosts = async () => {
      const db = getFirestore();
      const postsCollection = collection(db, 'kategoriler');
      const querySnapshot = await getDocs(postsCollection);
      const postData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setPosts(postData);
    };

    fetchPosts();
  }, []);

  const handleSearch = () => {
    // Use window.location.href to navigate
    window.location.href = `/ara/${searchInput}`;
  };

  return (
    <div className="container">
    <header className="border-bottom lh-1 py-3">
      <div className="row flex-nowrap justify-content-between align-items-center">
        <div className="col-4 pt-1">
          <a className="link-secondary" href="https://www.youtube.com/channel/UCizNcA0lVXwjnRmbJKhaCzA">Abone ol</a>
        </div>
        <div className="col-4 text-center">
          <a className="blog-header-logo text-body-emphasis text-decoration-none" href="/">VastSea Blog</a>
        </div>
        <div className="col-4 d-flex justify-content-end align-items-center">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="Ara..."
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <button className="btn btn-sm btn-outline-secondary" onClick={handleSearch}>
          
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="mx-3" role="img" viewBox="0 0 24 24"><title>Ara</title><circle cx="10.5" cy="10.5" r="7.5"></circle><path d="M21 21l-5.2-5.2"></path></svg>
            </button>
            <a className="btn btn-sm btn-outline-secondary" href="#">Giriş yap</a>
          </div>
        </div>
       
      </div>
    </header>
  
    <div className="nav-scroller py-1 mb-3 border-bottom">
      <nav className="nav nav-underline justify-content-between">
      <a className="nav-item nav-link link-body-emphasis " href={`/blog/hakk%C4%B1mda-rxhtvb`} >Hakkmızıda</a>
      {posts.map((post, index) => (
                   <a className="nav-item nav-link link-body-emphasis " href={`/etiket/${post.title}`} >{post.title}</a>
                  ))}
          
      </nav>
    </div>


  </div>
  
  );
}

export default Header;
