import React, { useState, useEffect } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import '../../styles/style.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { getFirestore, collection, addDoc, getDocs } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const BlogPostPage = () => {
  const [content, setContent] = useState('');
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('');
  const [image, setImage] = useState('');
  const [tags, setTags] = useState('');
  const [summary, setSummary] = useState('');
  const [categories, setCategories] = useState([]);
  const [showHtmlEditor, setShowHtmlEditor] = useState(false); // New state for toggling HTML editor

  const handleContentChange = (value) => {
    setContent(value);
  };
  
  const handleHtmlEditorChange = (e) => {
    setContent(e.target.value);
  };
  

  const fetchCategories = async () => {
    try {
      const db = getFirestore();
      const categoriesCollection = collection(db, 'kategoriler');
      const querySnapshot = await getDocs(categoriesCollection);

      const categoriesData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setCategories(categoriesData);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);
  const handleToggleHtmlEditor = () => {
    setShowHtmlEditor(!showHtmlEditor);
  };
  const handlePostSubmit = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (!user) {
        console.error('User not authenticated');
        return;
      }

      const db = getFirestore();

      // Create a random 6-character string for the URL
      const postUrl = createUrlFromTitle(title);

      // Split the tags string into an array using commas as separators
      const tagsArray = tags.split(',').map((tag) => tag.trim());

      const contentToSave = showHtmlEditor ? content : stripHtmlTags(content);

      // Create a new post document in the 'posts' collection
      const postRef = await addDoc(collection(db, 'posts'), {
        title: title,
        category: category,
        image: image,
        content: content,
        summary: summary, // Added the summary field
        authorId: user.uid,
        timestamp: new Date(),
        url: postUrl,
        tags: tagsArray,
      });

      console.log('Post added with ID: ', postRef.id);

      // Reset the form fields after successful submission
      setTitle('');
      setCategory('');
      setImage('');
      setContent('');
      setTags('');
      setSummary('');
    } catch (error) {
      console.error('Error adding post: ', error);
    }
  };

  const createUrlFromTitle = (title) => {
    // Remove special characters and replace spaces with a dash
    const sanitizedTitle = title.replace(/[^\w\s]/gi, '').replace(/\s+/g, '-');

    // Create a random 6-character string for uniqueness
    const randomString = Math.random().toString(36).substring(2, 8);

    return `${sanitizedTitle}-${randomString}`;
  };

  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link', 'video', 'customImage'],
      [{ script: 'sub' }, { script: 'super' }], // Add subscript and superscript
      [{ 'code-block': true }], // Add code-block option
      ['clean'],
    ],
    clipboard: {
      matchVisual: false,
    },
  };
  
  
  
  const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link',  'video', 'customImage',
  ];
  
  const handleContentChangeQuill = (value, delta, source, editor) => {
    if (source === 'user') {
      // Your existing logic for handling custom image
    }
  };
  

  const CustomImageBlot = Quill.import('formats/image');
  const ImageFormat = { ...CustomImageBlot, tagName: 'img' };
  Quill.register(ImageFormat, true);

  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  };

  return (
    <div className=" ">
      <div className="container mt-5">
        <h1 className="mb-4">Yeni Yazı Paylaş 🛠️</h1>
        <div className="mb-3">
        <h1> 
          <label htmlFor="postTitle" className="article-title">
            Başlık ✏️
          </label>
          </h1> 
          <input
            type="text"
            className="form-control"
            id="postTitle"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="mb-3">
        <h1> 
          <label htmlFor="postCategory" className="form-label">
            Kategori 📂
          </label>
          </h1> 
          <select
            className="form-select"
            id="postCategory"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            {categories.map((cat) => (
              <option key={cat.id} value={cat.title}>
                {cat.title} 📝
              </option>
            ))}
          </select>
        </div>
        <div className="mb-3">

        <h1> 
          <label htmlFor="postImage" className="form-label">
            Öne Çıkan Görsel (Link) 🖼️
          </label>
          </h1> 
          <input
            type="text"
            className="form-control"
            id="postImage"
            value={image}
            onChange={(e) => setImage(e.target.value)}
          />
        </div>
        <div className="mb-3">
        <h1>  <label htmlFor="postTags" className="form-label">
            Etiketler (Virgül ile ayırın)🏷️

          </label>
          </h1> 
          <input
            type="text"
            className="form-control"
            id="postTags"
            value={tags}
            onChange={(e) => setTags(e.target.value)}
          />
        </div>
        <div className="mb-3">
         <h1><label htmlFor="postSummary" className="form-label">
            Yazı Özeti 📃
          </label>
          </h1> 
          <textarea
            className="form-control"
            id="postSummary"
            rows="3"
            value={summary}
            onChange={(e) => setSummary(e.target.value)}
          ></textarea>
        </div>
        <div className="mb-3" style={{ height: '100vh' }}>
  <h1>
    <label htmlFor="postContent" className="form-label">
      İçerik 🗞️🧾🔗
    </label>
  </h1>
  {showHtmlEditor ? (
    <textarea
      className="form-control"
      id="postContent"
      rows="10"
      value={content}
      onChange={handleHtmlEditorChange} // Use the new handler for HTML editor
    />
  ) : (
    <ReactQuill
      style={{ height: '70vh' }}
      theme="snow"
      value={content}
      onChange={handleContentChange}
      modules={modules}
      formats={formats}
    />
  )}
  <br />
 
</div>


        <br />
        <button
    className="btn btn-secondary"
    onClick={handleToggleHtmlEditor}
  >
    {showHtmlEditor ? 'Rich Text Editor' : 'HTML Editor'}
  </button>
        <button className="btn btn-primary" onClick={handlePostSubmit}>
          Paylaş
        </button>
      </div>
    </div>
  );
};

export default BlogPostPage;