import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import moment from 'moment';
import 'moment/locale/tr'; 
import '../../styles/style.css';

const BlogPosts = () => {
  const [posts, setPosts] = useState([]);
  

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const db = getFirestore();
        const postsCollection = collection(db, 'posts');
        const querySnapshot = await getDocs(postsCollection);

        const postsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Gönderileri tarih sırasına göre sırala
        postsData.sort((a, b) => b.timestamp - a.timestamp);

        setPosts(postsData);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    fetchPosts();
  }, []); // Run only once on component mount

  return (
    <main className='container'>
       {posts.length > 0 && (
  <div className="p-4 p-md-5 mb-4 rounded text-body-emphasis bg-body-secondary"   >
   
     <div className="col-lg-6 px-0">

        <h1 className="display-4 fst-italic"><a href={`/blog/${posts[0].url}`} className="text-body-emphasis fw-bold"> {posts[0].title} </a></h1>
        <p className="lead my-3">{posts[0].summary}</p>
        <p className="lead mb-0"><a href={`/blog/${posts[0].url}`} className="text-body-emphasis fw-bold">Okumaya devam et...</a></p>
      </div>
      </div>
    )}
  

  <div className={`row mb-2`}>
    <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet"></link>
    {posts.slice(1).map((post, index) => (
      <div className="col-md-6" key={index}>
        <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
          <div className="col p-4 d-flex flex-column position-static">
            <strong className="d-inline-block mb-2 text-success-emphasis">{post.category}</strong>
            <h3 className="mb-0">{post.title.substring(0, 35)}</h3>
            <div className="mb-1 text-body-secondary">{moment(post.timestamp.toDate()).format(' HH DD MMMM YYYY')}</div>
            <p className="mb-auto">{post.summary.substring(0, 40)}</p>
            <a href={`/blog/${post.url}`} className="icon-link gap-1 icon-link-hover stretched-link">
              {post.title} 
              <svg className="bi"> {post.views || 0} <use xlinkHref="#chevron-right"></use></svg>
            </a>
          </div>
          <div className="col-auto d-none d-lg-block">
            <img
              className="bd-placeholder-img"
              width="200"
              height="250"
              src={post.image}
              alt="Thumbnail"
            />
          </div>
        </div>
      </div>
    ))}
  </div>
</main>

  );
};

export default BlogPosts;
