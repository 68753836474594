import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getFirestore, collection, getDocs, deleteDoc, doc, getDoc} from 'firebase/firestore';
import logo from "../admin/egehan.jpeg";
import '../../../styles/style.css';
import { useNavigate } from "react-router-dom";

const ProfilePage =({ isAuthenticated }) => {
  const navigate = useNavigate();
    useEffect(() => {
      // Check if the user is not authenticated, then navigate to the home page
      if (!isAuthenticated) {
        navigate("/");
      }
    }, [isAuthenticated, navigate]);
  
    const [posts, setPosts] = useState([]);
    const [userData, setUserData] = useState([]);
    const [totalViews, setTotalViews] = useState(0);
  
    useEffect(() => {
      const fetchPosts = async () => {
        const db = getFirestore();
        const postsCollection = collection(db, 'posts');
        const querySnapshot = await getDocs(postsCollection);
        const postData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setPosts(postData);
  
        // Toplam görüntülenme sayısını hesapla
        const totalViewsCount = postData.reduce((acc, post) => acc + (post.views || 0), 0);
        setTotalViews(totalViewsCount);
      };
  
      const fetchUserData = async () => {
        const db = getFirestore();
        const userDocRef = doc(db, 'users', 'cIMHBh9Jmca0hzGyXc2VdnF5jQl2');
  
        try {
          const userDocSnapshot = await getDoc(userDocRef);
  
          if (userDocSnapshot.exists()) {
            const userData = userDocSnapshot.data();
            setUserData(userData);
          } else {
            console.error('User not found');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      };
  
      fetchUserData();
      fetchPosts();
    }, []);
  
    const handleDeletePost = async (postId) => {
      const db = getFirestore();
      const postDoc = doc(db, 'posts', postId);
  
      try {
        await deleteDoc(postDoc);
        setPosts(posts.filter(post => post.id !== postId));
        console.log('Post deleted successfully');
      } catch (error) {
        console.error('Error deleting post:', error);
      }
    };
  return (
    <div  >
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            {/* meta */}
            <div className="profile-user-box card-box bg-custom">
              <div className="row">
                <div className="col-sm-6">
                  <span className="float-left mr-3">
                 
                  </span>
                  <div className="media-body text-white">
                    <h4 className="mt-1 mb-1 font-18">{userData.displayName}</h4>
                    <p className="font-13 text-light">{userData.Alan}</p>
                    <p className="text-light mb-0">{userData.adress}</p>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="text-right">
                    <button type="button" className="btn btn-light waves-effect">
                      <i className="mdi mdi-account-settings-variant mr-1"></i> Profili Düzenle
                    </button>
                    <br>
                    </br>
                    <br>
                    </br>
                    <a  href="/admin/yeniyazi"  className="btn btn-light waves-effect">
                      <i className="mdi mdi-account-settings-variant mr-1"></i> Yazı Ekle
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/*/ meta */}
          </div>
        </div>
        {/* end row */}
        <div className="row">
          <div className="col-xl-4">
            {/* Personal-Information */}
            <div className="card-box">
              <h4 className="header-title mt-0">Biyografi</h4>
              <div className="panel-body">
                <p className="text-muted font-13">
                {userData.bio}
                </p>
                <hr />
                <div className="text-left">
                  <p className="text-muted font-13">
                    <strong>Tam adı :</strong> <span className="m-l-15">{userData.displayName}</span>
                  </p>
                  <p className="text-muted font-13">
                    <strong>No :</strong><span className="m-l-15">{userData.phone}</span>
                  </p>
                  <p className="text-muted font-13">
                    <strong>Email :</strong> <span className="m-l-15">{userData.mail}</span>
                  </p>
                  <p className="text-muted font-13">
                    <strong>Konum:</strong> <span className="m-l-15">{userData.adress}</span>
                  </p>
                  <p className="text-muted font-13">
                    <strong>Diller :</strong>
     <span className="m-l-5" >
     <span className="flag-icon flag-icon-us m-r-5 m-t-0" title="us"></span> <span>  {userData.langs}</span>
    </span>           
                  </p>
                </div>
                <ul className="social-links list-inline mt-4 mb-0">
                  <li className="list-inline-item">
                    <a title="" data-placement="top" data-toggle="tooltip" className="tooltips" href="" data-original-title="Facebook">
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a title="" data-placement="top" data-toggle="tooltip" className="tooltips" href="" data-original-title="Twitter">
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a title="" data-placement="top" data-toggle="tooltip" className="tooltips" href="" data-original-title="Skype">
                      <i className="fa fa-skype"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {/* Personal-Information */}
             
            <div className="card-box ribbon-box">
                   {/*
              <div className="ribbon ribbon-primary">Mesajlar</div>
              <div className="clearfix"></div>
              <div className="inbox-widget">
           <a href="#">
                  <div className="inbox-item">
                    <div className="inbox-item-img"><img src="https://bootdey.com/img/Content/avatar/avatar2.png" className="rounded-circle" alt="" /></div>
                    <p className="inbox-item-author">Tomaslau</p>
                    <p className="inbox-item-text">I've finished it! See you so...</p>
                    <p className="inbox-item-date">
                      <button type="button" className="btn btn-icon btn-sm waves-effect waves-light btn-success">Reply</button>
                    </p>
                  </div>
                </a>
               
              </div>
                Diğer inbox-item'ları buraya ekleyebilirsiniz */}
            </div>
          </div>
          
          <div className="col-xl-8">
          <div className="row">
              <div className="col-sm-4">
                <div className="card-box tilebox-one">
                  <i className="icon-layers float-right text-muted"></i>
                  <h6 className="text-muted text-uppercase mt-0">Toplam Yazı</h6>
                  <h2 className="" data-plugin="counterup">✍🏻 {posts.length}</h2>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="card-box tilebox-one">
                  <i className="mdi mdi-eye float-right text-muted"></i>
                  <h6 className="text-muted text-uppercase mt-0">Toplam Görüntülenme</h6>
                  <h2 className="" data-plugin="counterup">👁️ {totalViews}</h2>
                </div>
              </div>
            </div>
              {/* Diğer col'ları buraya ekleyebilirsiniz 
            <div className="card-box">
              <h4 className="header-title mt-0 mb-3">Beceriler</h4>
              <div>
                <div>
                  <h5 className="text-custom">Lead designer / Developer</h5>
                  <p className="mb-0">websitename.com</p>
                  <p><b>2010-2015</b></p>
                  <p className="text-muted font-13 mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                </div>
                <hr />
   
              </div>
            </div>
          */}
            <div className="row">
        <div className="col-xl-10">
          <div className="card-box">
            <h4 className="header-title mb-3">Yazılar</h4>
            <a  href="/admin/yeniyazi"  className="btn btn-light waves-effect">
                      <i className="mdi mdi-account-settings-variant mr-1"></i> Yazı Ekle
                    </a>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Başlık</th>
                    <th>Kategori</th>
                    <th>Etiketler</th>
                    <th>Tarih</th>
                    <th>Görüntüleme</th>
                    <th>İşlemler</th>
                  </tr>
                </thead>
                <tbody>
                  {posts.map((post, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{post.title}</td>
                      <td>{post.category}</td>
                      <td>{post.tags && post.tags.join(', ')}</td>
                      <td>{post.timestamp?.toDate().toLocaleDateString()}</td>
                      <td>{post.views}</td>
                      <td>
                        <Link to={`/admin/yeniyazi/${post.id}`} className="btn btn-info btn-sm me-2">
                          Düzenle
                        </Link>
                        <button
                          className="btn btn-danger btn-sm"
                          onClick={() => handleDeletePost(post.id)}
                        >
                          Sil
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
          </div>
        </div>
        {/* end row */}
      </div>
      {/* container */}
    </div>
  );
}

export default ProfilePage;
