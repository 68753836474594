import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import moment from 'moment';
import 'moment/locale/tr'; 
import { useParams } from 'react-router-dom';
import '../../styles/style.css';

const Search = () => {
  const [posts, setPosts] = useState([]);
  const { tag } = useParams();

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const db = getFirestore();
        const postsCollection = collection(db, 'posts');
        const querySnapshot = await getDocs(postsCollection);

        const postsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setPosts(postsData);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    fetchPosts();
  }, []);

  console.log('Current tag:', tag);

  const searchResults = posts.filter((post) =>
    post.title.toLowerCase().includes(tag.toLowerCase()) ||
    post.summary.toLowerCase().includes(tag.toLowerCase()) ||
    (post.tags && post.tags.some((postTag) => postTag.toLowerCase().includes(tag.toLowerCase())))
  );

  return (
    <Container>
      <Row className="mt-n5">
        <Col md={12}>
          <h1> 🔎 "{tag}" için çıkan sonuçlar: </h1>
        </Col>
        {searchResults.map((post) => (
           <div className="col-md-6"  >
           <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
             <div className="col p-4 d-flex flex-column position-static">
               <strong className="d-inline-block mb-2 text-success-emphasis">{post.category}</strong>
               <h3 className="mb-0">{post.title.substring(0, 35)}</h3>
               <div className="mb-1 text-body-secondary">{moment(post.timestamp.toDate()).format(' HH DD MMMM YYYY')}</div>
               <p className="mb-auto">{post.summary.substring(0, 40)}</p>
               <a href={`/blog/${post.url}`} className="icon-link gap-1 icon-link-hover stretched-link">
                 {post.title} 
                 <svg className="bi"> {post.views || 0} <use xlinkHref="#chevron-right"></use></svg>
               </a>
             </div>
             <div className="col-auto d-none d-lg-block">
               <img
                 className="bd-placeholder-img"
                 width="200"
                 height="250"
                 src={post.image}
                 alt="Thumbnail"
               />
             </div>
           </div>
         </div>
        ))}
       
      </Row>
    </Container>
  );
};

export default Search;
