import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faInstagram, faGithub} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const Footer = () => {
  return (
    <footer className="py-5 text-center text-body-secondary bg-body-tertiary">
    <p>VastSea Blog  by <a href="https://instagram.com/marsstakiuzayliyim">@marsstakiuzayliyim</a>.</p>
    <p class="mb-0">
       
    </p>
  </footer>
  );
}

export default Footer;
