import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import BlogPostPage from "../../utilys/BlogPostPage";

const AddPost = ({ isAuthenticated }) => {
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is not authenticated, then navigate to the home page
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  return (
    <>
      <Container>
        <Row>
          <Col>
            <BlogPostPage />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddPost;
