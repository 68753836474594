import React from "react";
import '../../../styles/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Col, Container, Row, Nav, Button, Form, Navbar, FormControl } from "react-bootstrap"; 
import Header from "../../utilys/Head";
import BlogPosts from "../../utilys/BlogPost";
import Footer from "../../utilys/Footer";
import CategorysPage from "../../utilys/CategorysPage";


const CategorysBlog = () => {
return (
    <>
  

    <Container>
        <Row>
            <Col>
            <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet"></link>
          <CategorysPage  />
            </Col>
        </Row>
    </Container>

    </>
);
}

export default CategorysBlog;