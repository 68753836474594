// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { signInWithEmailAndPassword, getAuth } from 'firebase/auth';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAO-AVsbAqUOSuWWBQ4OEt57BhSpLU4KhQ",
  authDomain: "vastseablogweb.firebaseapp.com",
  projectId: "vastseablogweb",
  storageBucket: "vastseablogweb.appspot.com",
  messagingSenderId: "1025679968798",
  appId: "1:1025679968798:web:5b157c452e26d4e69abc0f",
  measurementId: "G-6ZVDRQHV6M"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db }; // Export auth and db for use in other files
export default app;