// App.js

// Import gerekli bileşenleri
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import HomePage from './components/pages/client/HomePage';
import BlogSingle from './components/utilys/BlogSingle';
import Footer from './components/utilys/Footer';
import Header from './components/utilys/Head';
import AddPost from './components/pages/admin/AddPost';
import ProfilePage from './components/pages/admin/ProfilePage';
import LoginPage from './components/pages/admin/LoginPage';
import SignUpPage from './components/pages/admin/SignUpPage';
import UserProfile from './components/pages/admin/UserProfile';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import 'bootstrap/dist/css/bootstrap.min.css';
import CategorysBlog from './components/pages/client/Category';
import Search from './components/utilys/Search';
// App component
function App() {
  // Define isAuthenticated state and a function to update it
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user); // Set isAuthenticated based on whether user is present
    });

    // Cleanup the observer when the component unmounts
    return () => unsubscribe();
  }, []);

  return (
    <>
      <Header />

      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/etiket/:tag" element={<CategorysBlog />} />
          <Route path="/ara/:tag" element={<Search />} />
          <Route path="/blog/:slug" element={<BlogSingle />} />
          <Route path="/kayitol" element={<SignUpPage />} />
          <Route path="/giris" element={<LoginPage />} />
          {isAuthenticated && (
            <>
          <Route path="/admin" element={<ProfilePage isAuthenticated={isAuthenticated} />} />
          <Route path="/admin/yeniyazi" element={<AddPost isAuthenticated={isAuthenticated} />} />
          </>
           )}

       

        </Routes>
      </Router>

      <Footer />
    </>
  );
}

export default App;
