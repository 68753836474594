import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getFirestore, collection, getDocs, query, where, addDoc } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import '../../styles/style.css';

const CommentBlock = () => {
    const [newComment, setNewComment] = useState('');
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [comments, setComments] = useState([]);
    const { slug } = useParams();

    // Firestore bağlantısı için referans al
    const db = getFirestore();

    // Yorumları Firestore'dan çek
    useEffect(() => {
        const fetchComments = async () => {
            const q = query(collection(db, 'comments'), where('slug', '==', slug));
            const querySnapshot = await getDocs(q);
            const commentList = [];
            querySnapshot.forEach((doc) => {
                commentList.push(doc.data());
            });
            setComments(commentList);
        };

        fetchComments();
    }, [slug, db]);

    // Yorum gönderme işlemi
    const handleCommentSubmit = async (e) => {
        e.preventDefault(); // Sayfanın yeniden yüklenmesini engelle

        // Yeni yorumu Firestore'a ekle
        const commentData = {
            author: userName,
            email: userEmail,
            text: newComment,
            timestamp: new Date().toLocaleString(),
            slug: slug,
        };

        // Firestore'a yorumu ekle
        await addDoc(collection(db, 'comments'), commentData);

        // Yorum eklenen veriyi güncelle
        setComments([...comments, commentData]);

        // Giriş alanlarını temizle
        setUserName('');
        setUserEmail('');
        setNewComment('');
    };

    return (
        <>
        <div  >
            <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet"></link>
            <div className="be-comment-block" style={{minWidth: '100%'}} >
                <h1 className="comments-title">Yorumlar ({comments.length})</h1>

                {/* Yorum listesi */}
                {comments.map((comment, index) => (
                    <div className="be-comment"style={{minWidth: '100%'}}  key={index}>
                        <div className="be-img-comment">
                            <a >
                                <img src= "https://bootdey.com/img/Content/avatar/avatar2.png" alt="" className="be-ava-comment" />
                            </a>
                        </div>
                        <div className="be-comment-content">
                            <span className="be-comment-name">
                                <a >{comment.author}</a>
                            </span>
                            <span className="be-comment-time">
                                {comment.timestamp}
                            </span>
                            <p className="be-comment-text">{comment.text}</p>
                        </div>
                    </div>
                ))}

                {/* Yorum yazma formu */}
                <form className="form-block"style={{minWidth: '100%'}}  onSubmit={handleCommentSubmit}>
                    <div className="row">
                        <div className="col-xs-18 col-sm-6">
                            <div className="form-group ">
                                
                                <input
                                    className="form-input"
                                    type="text"
                                    placeholder="Adını gir"
                                    value={userName}
                                    onChange={(e) => setUserName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6  ">
                            <div className="form-group  ">
                                
                                <input
                                    className="form-input"
                                    type="text"
                                    placeholder="Mail Adresini gir"
                                    value={userEmail}
                                    onChange={(e) => setUserEmail(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-xs-12">
                            <div className="form-group">
                                <textarea
                                    className="form-input"
                                    required=""
                                    placeholder="Düşünceni yaz"
                                    value={newComment}
                                    onChange={(e) => setNewComment(e.target.value)}
                                ></textarea>
                            </div>
                        </div>
                        <button className="btn btn-primary pull-right" type="submit">
                            Paylaş
                        </button>
                    </div>
                </form>
            </div>
        </div>
        </>
    );
};

export default CommentBlock;
