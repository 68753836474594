import React from "react";
import '../../../styles/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Col, Container, Row, Nav, Button, Form, Navbar, FormControl } from "react-bootstrap"; 
import Header from "../../utilys/Head";
import BlogPosts from "../../utilys/BlogPost";
import Footer from "../../utilys/Footer";
const HomePage = () => {
return (
    <>
  

    <Container className="gray-bg">
        <Row>
            <Col xs={4}>
                
            </Col>
            <Col xs={10}>
            <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet"></link>
            <BlogPosts />
            </Col>
            <Col xs={4}>
              
            </Col>
        </Row>
    </Container>

    </>
);
}

export default HomePage;